/* Overrides para F7 */
:root {
    --f7-theme-color: #0fb0c0;
    --f7-theme-color-rgb: 15, 176, 192;
    --f7-theme-color-shade: #0d8d99;
    --f7-theme-color-tint: #11cfe0;
    --f7-theme-color-tint-rgb: 17, 207, 224;
}

/*************************************************
 * Estilos personalizados desde acá
 ************************************************/
:root {
    --color-teal: #0fb0c0;
    --color-teal-light: #00c6c8;
    --color-naranja: #fc502a;
    --color-amarillo: #fca629;
    --color-amarillo-claro: #fdc74f;
    --color-celeste: #01abff;
    --background-fresco: linear-gradient(135deg, var(--color-teal) 0%, var(--color-amarillo-claro) 100%);
    --background-ocaso: linear-gradient(135deg, rgba(8,177,255,1) 0%, rgba(138,69,162,1) 33%, rgba(239,10,82,1) 66%, rgba(255,149,35,1) 100%);
}

/*
 * Estilos exclusivos para ser usados en navegadores de escritorio y resoluciones grandes.
 */
 @media (min-width: 1000px) {
    body {
        background: var(--background-ocaso);
    }
    div.framework7-root {
        margin: 0 auto;
        width: 900px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    }
}

/*
 * Navbar
 */
.ndb-navbar {
    color: white;
}
.ndb-navbar .navbar-bg {
    background: var(--background-ocaso);
}

/*
 * Video
 */
.ndb-video {
    border-radius: var(--f7-card-border-radius);
    box-shadow: var(--f7-card-box-shadow);
    width: 90%;
}
@media (min-width: 1000px) {
    .ndb-video {
        width: auto;
        height: 70%;
    }
}

/*
 * Detalles de elementos de pagina
 */
.ndb-page .block-title {
    color: var(--color-teal);
    border-bottom: 2px solid var(--color-teal);
    padding-bottom: 4px;
    text-transform: uppercase;
}